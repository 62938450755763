import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { addAlertMessage } from '../../../common/consts/functions';
import { MY_ZONE_TOKEN } from '../../../common/consts/strings';
import { CHECK_TOKEN } from '../../../common/services/api';

const AuthCheckToken: React.FC<{roleIdArr: number[]}> = ({roleIdArr}) => {
  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    isTokenExpired();
  }, [location.pathname])

  const isTokenExpired = async () => {
    if (!localStorage[MY_ZONE_TOKEN]) history.push('/login');
    else {
      let res = await CHECK_TOKEN();
      if(!res.isSuccessful){
          addAlertMessage("Info", `your session has ended, log in again`, "info");
          localStorage.clear();
          history.push('/login');
      }
    }
  }
  return (
    <></>
  );
}

export default AuthCheckToken;