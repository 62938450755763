import { IActiveChildren, IAdminUserRequest, IAdminUserResponse, IApiResponseModel, IChildUserReponse, IFeedbackChildren, IFeedbackMessageRequest, IJourneysSummaryResponse, ILoginRequest, ILoginResponse, IMessageResponse, IParentUserRequest, IParentUserResponse, IPrizeChildren } from "../consts/model";
import { MY_ZONE_TOKEN } from "../consts/strings";
import * as API_ROUTES from '../../common/consts/api_routes';


const FETCH_API = async <T>(_url: string, _method: "POST" | "PUT" | "PATCH" | "DELETE" | "GET", _body?: any, isToken = true, isUpload = false): Promise<IApiResponseModel<T>> => {
    const headers = new Headers();
    if (isUpload) {
      headers.append('Content-Type', 'undefined')
    } else {
      headers.append('content-type', 'application/json');
    }
    if (isToken) headers.append('Authorization', `Bearer ${localStorage[MY_ZONE_TOKEN]}`);
      const resp = await fetch(_url, {
        method: _method,
        body: isUpload ? _body : JSON.stringify(_body),
        headers
      });
      return await resp.json();
  }
  

  const LOGIN = async (_body: ILoginRequest): Promise<IApiResponseModel<ILoginResponse>> => {
    const data = await FETCH_API<ILoginResponse>(API_ROUTES.LOGIN, "POST", _body, false);
    if (data.isSuccessful) {
      localStorage.setItem(MY_ZONE_TOKEN, data.data.currentJwt);
      return data;
    }
    return data;
  }

  const LOGOUT = async (): Promise<IApiResponseModel<boolean>> => {
      const data = await FETCH_API<boolean>(API_ROUTES.LOGOUT, "POST", {token: localStorage[MY_ZONE_TOKEN]});
      if (data.isSuccessful) {
          localStorage.clear();
      }
      return data;
  }

  const CHECK_TOKEN = async (): Promise<IApiResponseModel<boolean>> => FETCH_API<boolean>(API_ROUTES.CHECK_TOKEN, "GET");

  const GET_ADMIN_USERS = async (): Promise<IApiResponseModel<IAdminUserResponse[]>> => FETCH_API(API_ROUTES.GET_ADMIN_USERS, "GET");
  const GET_PARENT_USERS = async (): Promise<IApiResponseModel<IParentUserResponse[]>> => FETCH_API(API_ROUTES.GET_PARENT_USERS, "GET");
  const GET_CHILD_USERS = async (): Promise<IApiResponseModel<IChildUserReponse[]>> => FETCH_API(API_ROUTES.GET_CHILD_USERS, "GET");

  const GET_ADMIN_BY_ID = async (userId: string): Promise<IApiResponseModel<IAdminUserResponse>> => FETCH_API(`${API_ROUTES.GET_ADMIN_USER_BY_ID}/${userId}`, 'GET');
  // as
  const GET_USER_PRIZE_BY_ID = async (userPrizeId: string): Promise<IApiResponseModel<any>> => FETCH_API(`${API_ROUTES.GET_USER_PRIZE_BY_ID}/${userPrizeId}`, 'GET');
  
  const GET_CHILDE_BY_ID = async (userId: string): Promise<IApiResponseModel<any>> => FETCH_API(`${API_ROUTES.ADMIN_GET_CHILD_USER_BY_ID}/${userId}`, 'GET');
  const UPDATE_ADMIN_USER = async (admin: Partial<IAdminUserResponse>): Promise<IApiResponseModel<IAdminUserResponse>> => FETCH_API(API_ROUTES.UPDATE_ADMIN_USER, 'PUT', admin);
  const CREATE_ADMIN_USER = async (admin: Partial<IAdminUserRequest>): Promise<IApiResponseModel<IAdminUserResponse>> => FETCH_API(API_ROUTES.CREATE_ADMIN_USER, 'POST', admin);

  const GET_PARENT_BY_ID = async (parentId: string): Promise<IApiResponseModel<IParentUserResponse>> => FETCH_API(`${API_ROUTES.GET_PARENT_USER_BY_ID}/${parentId}`, 'GET');
  const UPDATE_PARENT_USER = async (parent: Partial<IParentUserRequest>): Promise<IApiResponseModel<IParentUserResponse>> => FETCH_API(API_ROUTES.UPDATE_PARENT_USER, 'PUT', parent);

  const DELETE_ADMIN_USER = async (adminId: string): Promise<IApiResponseModel<boolean>> => FETCH_API(`${API_ROUTES.DELETE_ADMIN_USER}/${adminId}`, 'DELETE');
  const DELETE_PARENT_USER = async (parentId: string): Promise<IApiResponseModel<boolean>> => FETCH_API(`${API_ROUTES.DELETE_PARENT_USER}/${parentId}`, 'DELETE');
  const DELETE_CHILDE_USER = async (childId: string): Promise<IApiResponseModel<boolean>> => FETCH_API(`${API_ROUTES.DELETE_CHILD_USER}/${childId}`, 'DELETE');

  const GET_MESSAGES = async (): Promise<IApiResponseModel<any>> => FETCH_API(`${API_ROUTES.GET_All_MESSAGES}`, 'GET');
  const GET_MESSAGE_BY_ID = async (messageId: number): Promise<IApiResponseModel<IMessageResponse>> => FETCH_API(`${API_ROUTES.GET_MESSAGE_BY_ID}/${messageId}`, 'GET');
  const GET_FEEDBACK_MESSAGE_BY_ACTIVITY_ID = async (activityId: number): Promise<IApiResponseModel<IMessageResponse>> => FETCH_API(`${API_ROUTES.GET_FEEDBACK_MESSAGE_BY_ACTIVITY_ID}/${activityId}`, 'GET');
  const CREATE_UPDATE_FEEDBACK_MESSAGE = async (feedbackMessage: IFeedbackMessageRequest): Promise<IApiResponseModel<any>> => FETCH_API(feedbackMessage.id ? API_ROUTES.UPDATE_FEEDBACK_MESSAGE : API_ROUTES.CREATE_FEEDBACK_MESSAGE, feedbackMessage.id ? 'PUT' : 'POST', feedbackMessage);
  const CREATE_ACTUAL_MESSAGE = async (body:any): Promise<IApiResponseModel<any>> => FETCH_API(`${API_ROUTES.CREATE_ACTUAL_MESSAGES}`, 'POST', body, true, true);
  const ADMIN_EDIT_CHILD = async (body:any): Promise<IApiResponseModel<any>> => FETCH_API(`${API_ROUTES.ADMIN_EDIT_CHILD_USER}`, 'PUT', body);
  const ADMIN_EDIT_USER_PRIZE_STATUS = async (userPrizeId:number ,body:{newStatus:number , dateFulfilled?:string}): Promise<IApiResponseModel<boolean>> => FETCH_API(`${API_ROUTES.UPDATE_USERS_PRIZES_STATUS}/${userPrizeId}`, 'PUT', body);
  const ADMIN_GET_CHILD_PERCENT = async (childId:string ): Promise<IApiResponseModel<IJourneysSummaryResponse[]>> => FETCH_API(`${API_ROUTES.ADMIN_GET_PERCENT_CHILD_BY_ID}/${childId}`, 'GET');
  // dashboard
  const DASHBOARD_GET_ACTIVE_USER = async (): Promise<IApiResponseModel<IActiveChildren>> => FETCH_API(`${API_ROUTES.GET_ALL_ACTIVE_USER}`, 'GET');
  const DASHBOARD_GET_FEEDBACK_USER = async (): Promise<IApiResponseModel<IFeedbackChildren>> => FETCH_API(`${API_ROUTES.GET_ALL_FEEDBACK_USER}`, 'GET');
  const DASHBOARD_GET_PRIZE_USER = async (): Promise<IApiResponseModel<any>> => FETCH_API(`${API_ROUTES.GET_ALL_PRIZE_USER_STATISTICS}`, 'GET');
  const DASHBOARD_GET_GENERAL_STATISTICS = async (): Promise<IApiResponseModel<any>> => FETCH_API(`${API_ROUTES.GET_GENERAL_STATISTICS}`, 'GET');
  const DASHBOARD_GET_JOURNEY_STATISTICS = async (): Promise<IApiResponseModel<any>> => FETCH_API(`${API_ROUTES.GET_JOURNEY_STATISTICS}`, 'GET');
  // content-editor
  const CONTENT_EDITOR_GET_PAGES= async (): Promise<IApiResponseModel<any>> => FETCH_API(`${API_ROUTES.GET_CONTENT_PAGES}`, 'GET');
  const CONTENT_EDITOR_GET_PAGES_BY_ID = async (pageId:string): Promise<IApiResponseModel<any>> => FETCH_API(`${API_ROUTES.GET_CONTENT_SINGLE_PAGE}/${pageId}`, 'GET');
  const CONTENT_EDITOR_UPDATE_PAGES = async (body:any): Promise<IApiResponseModel<any>> => FETCH_API(`${API_ROUTES.GET_CONTENT_SINGLE_PAGE}`, 'PUT', body);
  const CONTENT_EDITOR_DELETE_IMG = async (imgId:string): Promise<IApiResponseModel<any>> => FETCH_API(`${API_ROUTES.DELETE_CONTENT_SINGLE_GALLERY}/${imgId}`, 'DELETE');
  
  export {LOGIN, 
          CHECK_TOKEN, 
          LOGOUT, 
          GET_ADMIN_USERS, 
          GET_PARENT_USERS, 
          GET_CHILD_USERS, 
          DELETE_ADMIN_USER, 
          GET_ADMIN_BY_ID,
          UPDATE_ADMIN_USER,
          CREATE_ADMIN_USER,
          DELETE_PARENT_USER,
          DELETE_CHILDE_USER,
          GET_PARENT_BY_ID,
          UPDATE_PARENT_USER,
          CREATE_UPDATE_FEEDBACK_MESSAGE,
          GET_FEEDBACK_MESSAGE_BY_ACTIVITY_ID,
          GET_MESSAGE_BY_ID,
          GET_MESSAGES,
          CREATE_ACTUAL_MESSAGE,
          GET_CHILDE_BY_ID,
          ADMIN_EDIT_CHILD,
          GET_USER_PRIZE_BY_ID,
          ADMIN_EDIT_USER_PRIZE_STATUS,
          ADMIN_GET_CHILD_PERCENT,
          DASHBOARD_GET_ACTIVE_USER,
          DASHBOARD_GET_FEEDBACK_USER,
          DASHBOARD_GET_PRIZE_USER,
          DASHBOARD_GET_GENERAL_STATISTICS,
          DASHBOARD_GET_JOURNEY_STATISTICS,
          CONTENT_EDITOR_GET_PAGES_BY_ID,
          CONTENT_EDITOR_UPDATE_PAGES,
          CONTENT_EDITOR_DELETE_IMG,
          CONTENT_EDITOR_GET_PAGES
        };