import { NAVIGATION_INIT, OPEN_SHORTCUT, CLOSE_SHORTCUT,SET_USER } from "./NavigationActions";
import profile from '../../assets/icon-images/profile.png';

export function navigationReducer(
  state = {
    shortcutOpen: false,
    items: [],
    user: {
      role: "manager",
      icon: profile
    }
  },
  action
) {
  switch (action.type) {
    case NAVIGATION_INIT:
      return saveToLocalStorage({
        ...state,
        items: action.payload
      });

    case OPEN_SHORTCUT:
      return saveToLocalStorage({
        ...state,
        shortcutOpen: true
      });
    case CLOSE_SHORTCUT:
      return saveToLocalStorage({
        ...state,
        shortcutOpen: false
      });

    case SET_USER:
      return saveToLocalStorage({
        ...state,
        user: action.user
      });


    default:
      return (localStorage["navigation"]) ? JSON.parse(localStorage["navigation"]) : state;
  }
}
const saveToLocalStorage = (stateToSave) => {
  localStorage.setItem("navigation", JSON.stringify(stateToSave));
  return stateToSave;
}