// const base_url = 'http://localhost:5555/api';     // local

// const base_url = 'http://18.195.31.71:1100/api';  // dev

// const base_url = 'http://18.195.31.71:1103/api';  // stage 

// const base_url = 'http://18.195.31.71:1113/api';  // qa 

// const base_url = 'http://35.156.232.184:1001/api';  // prod 

const base_url = 'https://api.tomyzone.com/api';  // prod 
const createUrl = (url: string): string => `${base_url}/${url}`;



//auth
const LOGIN = createUrl('auth/admin/login');
const LOGOUT = createUrl('auth/admin/logout');
const CHECK_TOKEN = createUrl('auth/admin/check-token');

// users
const GET_ADMIN_USERS = createUrl('users/admin/admin-users');
const GET_PARENT_USERS = createUrl('users/admin/parent-users');
const GET_CHILD_USERS = createUrl('users/admin/children-users');
const GET_ADMIN_USER_BY_ID = createUrl('users/admin/user-admin');
const GET_PARENT_USER_BY_ID = createUrl('users/admin/user-parent');
const ADMIN_GET_CHILD_USER_BY_ID = createUrl('users/admin/user-child');


const ADMIN_GET_PERCENT_CHILD_BY_ID = createUrl('activity/admin/percent');
const ADMIN_EDIT_CHILD_USER = createUrl('users/admin/update/user');
const UPDATE_ADMIN_USER = createUrl('users/admin/update/admin');
const UPDATE_PARENT_USER = createUrl('users/admin/update/parent');
const CREATE_ADMIN_USER = createUrl('users/admin/create/admin');
const DELETE_ADMIN_USER = createUrl('users/admin/delete/admin');
const DELETE_PARENT_USER = createUrl('users/admin/delete/parent');
const DELETE_CHILD_USER = createUrl('users/admin/delete/child');

const GET_USER_ACTIVITY = createUrl('activity/admin');

const GET_FEEDBACK_MESSAGE_BY_ACTIVITY_ID = createUrl('messages/admin/feedback');
const GET_MESSAGE_BY_ID = createUrl('messages/admin');
const CREATE_FEEDBACK_MESSAGE = createUrl('messages/admin/create/feedback');
const UPDATE_FEEDBACK_MESSAGE = createUrl('messages/admin/update/feedback');
const GET_All_MESSAGES = createUrl('messages/admin');
const CREATE_ACTUAL_MESSAGES = createUrl('messages/admin/create/actual');

// prizes
const GET_ALL_PRIZES = createUrl("prizes/admin")
const GET_USER_PRIZE_BY_ID = createUrl("prizes/admin/user-prize")
const GET_ALL_USERS_PRIZES = createUrl("prizes/admin/user-prizes")
const UPDATE_USERS_PRIZES_STATUS = createUrl("prizes/admin/user-prize/edit")
// dashboard
const GET_ALL_ACTIVE_USER = createUrl("dasdoard/admin/active-users")
const GET_ALL_FEEDBACK_USER = createUrl("dasdoard/admin/Feedback-message")
const GET_ALL_PRIZE_USER_STATISTICS = createUrl("dasdoard/admin/prize-statistics")
const GET_GENERAL_STATISTICS = createUrl("dasdoard/admin/general-statistics")
const GET_JOURNEY_STATISTICS = createUrl("dasdoard/admin/journey-statistics")
// content-editor
const GET_CONTENT_PAGES = createUrl("content/pages")
const GET_CONTENT_SINGLE_PAGE = createUrl("content/page")
const GET_CONTENT_GALLERY = createUrl("content/images")
const DELETE_CONTENT_SINGLE_GALLERY = createUrl("content/image")
const CREATE_CONTENT_GALLERY = createUrl("content/image/upload")


export {base_url, 
        LOGIN, 
        CHECK_TOKEN, 
        LOGOUT, 
        GET_ADMIN_USERS, 
        GET_PARENT_USERS, 
        GET_CHILD_USERS, 
        DELETE_ADMIN_USER, 
        GET_ADMIN_USER_BY_ID,
        GET_PARENT_USER_BY_ID,
        UPDATE_ADMIN_USER,
        CREATE_ADMIN_USER,
        DELETE_PARENT_USER,
        DELETE_CHILD_USER,
        UPDATE_PARENT_USER,
        GET_USER_ACTIVITY,
        GET_FEEDBACK_MESSAGE_BY_ACTIVITY_ID,
        GET_MESSAGE_BY_ID,
        CREATE_FEEDBACK_MESSAGE,
        UPDATE_FEEDBACK_MESSAGE,
        GET_All_MESSAGES,
        CREATE_ACTUAL_MESSAGES,
        GET_ALL_USERS_PRIZES,
        ADMIN_GET_CHILD_USER_BY_ID,
        ADMIN_EDIT_CHILD_USER,
        GET_USER_PRIZE_BY_ID,
        UPDATE_USERS_PRIZES_STATUS,
        ADMIN_GET_PERCENT_CHILD_BY_ID,
        GET_ALL_ACTIVE_USER,
        GET_ALL_FEEDBACK_USER,
        GET_ALL_PRIZE_USER_STATISTICS,
        GET_GENERAL_STATISTICS,
        GET_JOURNEY_STATISTICS,
        GET_CONTENT_PAGES,
        GET_CONTENT_SINGLE_PAGE,
        GET_CONTENT_GALLERY,
        DELETE_CONTENT_SINGLE_GALLERY,
        CREATE_CONTENT_GALLERY
};