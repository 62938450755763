import React, { useEffect, useState } from "react";
import ToggleShortcut from "./ToggleShortcut";
import { connect } from "react-redux";
import profile from '../../../assets/icon-images/profile.png';
import * as NavigationActions from '../NavigationActions'
import { bindActionCreators } from "redux";

const LoginInfo = props => {
  const [user, setUser] = useState('User');

  useEffect(() => {
    const navigation = JSON.parse( localStorage["navigation"]);
    if (navigation && navigation.user && navigation.user.user && navigation.user.user.role) {
      setUser(navigation.user.user.role.value || 'User');
    }
  }, [localStorage["navigation"]]);
  return (
    <div className="login-info">
      <span>
        <ToggleShortcut>
          <img src={profile} alt="user name" className="online" style={{paddingLeft:"3px"}} />
          <span>{user}</span>
          <i className="fa fa-angle-down" />
        </ToggleShortcut>
      </span>
    </div>
  );
}

export default connect(
  store => store.navigation,
  dispatch => bindActionCreators(NavigationActions, dispatch)
)(LoginInfo);
