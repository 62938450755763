import React, { useEffect, useState } from "react";
import classnames from "classnames";
import $ from "jquery";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as I18nActions from "../I18nActions";

const LanguageSelector = props => {
const [prevLanguage, setPrevLanguage] = useState();
let languages = props.languages;
let language = props.language;

  useEffect(() => {
    if (localStorage["language"])
      setPrevLanguage(JSON.parse(localStorage["language"]).language.key);
    else
      setPrevLanguage(language.key);
  }, []);

 const selectLanguage = language => {
   props.i18nSelect(language);
   if(prevLanguage !== language.key){
     $('#rtlSwitcher').trigger('click');
   }
   setPrevLanguage(language.key);
};
  


  if (!props.language) return <div />;
  return (
    <ul className="header-dropdown-list ng-cloak">
      <li className="dropdown">
        <div className="dropdown-toggle" data-toggle="dropdown">
          <img
            src="assets/img/blank.gif"
            className={classnames(["flag", "flag-" + language.key])}
            alt={language.alt}
          />
          <span>&nbsp;{language.title}&nbsp;</span>
          <i className="fa fa-angle-down" />
        </div>
        <ul className="dropdown-menu pull-right">
          {languages.map((lang, idx) => {
            return (
              <li
                key={idx}
                className={classnames({
                  active: lang.key === language.key
                })}
              >
                <div className={`${lang.key === language.key && "active-div-menu"}`} onClick={e => selectLanguage(lang)}>
                  <img
                    src="assets/img/blank.gif"
                    className={classnames(["flag", "flag-" + lang.key])}
                    alt={lang.alt}
                  />
                  &nbsp;
                  <span>{lang.title}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </li>
    </ul>
  );
}


export default connect(
  store => store.i18n,
  dispatch => bindActionCreators(I18nActions, dispatch),

)(LanguageSelector);
