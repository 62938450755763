import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";


const UsersViewChildren = Loadable({
  loader: () => import("../role-admin/users/usersViewChildren"),
  loading: Loading
});

// const DashboardExample = Loadable({
//   loader: () => import("./dashboard/components/Dashboard"),
//   loading: Loading
// });

const DatatableExample = Loadable({
  loader: () => import("./datatable-page-example/datatableExample"),
  loading: Loading
});

const GraphExample = Loadable({
  loader: () => import("./graphs-page-example/graphsPageExample"),
  loading: Loading
});

const ModalExample = Loadable({
  loader: () => import("./modal-page-example/modalPageExample"),
  loading: Loading
});


export const routes = [
  // {
  //   path: "/",
  //   exact: true,
  //   component: UsersViewChildren,
  //   name: "Children"
  // },
  // {
  //   path: "/",
  //   exact: true,
  //   component: DashboardExample,
  //   name: "DatatableExample"
  // },
  {
    path: "/graphs-example",
    exact: true,
    component: GraphExample,
    name: "graphsPageExample"
  },
  {
    path: "/modal-example",
    exact: true,
    component: ModalExample,
    name: "modalPageExample"
  }
];
