import { routes as auth } from "./views/auth/index";
import { routes as example } from "./views/example/index";
import { routes as appViews } from "./views/app-views/index";
import { routes as usersView } from './views/role-admin/';


export const routes = [
  ...appViews,
  ...example,
  ...usersView
];

export const authRoutes = [
  ...auth
];
