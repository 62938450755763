import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Shortcut, Navigation } from "../../navigation";
// import Ribbon from "./Ribbon";
// import LayoutSwitcher from "./LayoutSwitcher";
import Header from "./Header";
import Footer from "./Footer";

import { routes } from "../../../routes";
import { LayoutSwitcher } from ".";
import AuthCheckToken from "../../../views/auth/components/authCheckToken";


const Layout = (props) => {
  return (
    <div>
      <AuthCheckToken />
      <Header />
      <Navigation />
      <div id="main" role="main">
        {/* settings */}
        <LayoutSwitcher />
        <Switch>
          {/* router goes here */}
          {routes.map((route, idx) => {
            return route.component ? (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={props => <route.component {...props} />}
              />
            ) : null;
          })}
          <Redirect path='*' exact={true} to="/" />
        </Switch>
      </div>
      {/* <Redirect from="/" to="/programs/dashboard" /> */}
      <Footer />
      <Shortcut />
    </div>
  );
}

export default Layout;
