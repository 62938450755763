import $ from 'jquery'
import React from 'react'
import { NavLink,useLocation } from 'react-router-dom';

import {Msg} from '../../i18n'

import SmartMenuList from './NavMenuList'


const SmartMenuItem = props => {
  const location = useLocation();

  const onLinkClick = (e)=>{
    const $body = $("body");
    const $html = $("html");

    if (!$body.hasClass("menu-on-top")) {
      $html.removeClass("hidden-menu-mobile-lock");
      $body.removeClass("hidden-menu");
      $body.removeClass("minified");
    } else if (
      $body.hasClass("menu-on-top") &&
      $body.hasClass("mobile-view-activated")
    ) {
      $html.removeClass("hidden-menu-mobile-lock");
      $body.removeClass("hidden-menu");
      $body.removeClass("minified");
    }
    
  }

    const item = props.item;

    const title = !item.parent ?
      <span className="menu-item-parent"><Msg phrase={item.title}/></span> :
      <Msg phrase={item.title}/>;

    const badge = item.badge ? <span className={item.badge.class}>{item.badge.label || ''}</span> : null;
    const childItems = item.items ? <SmartMenuList items={item.items}/> : null;

    const icon = item.icon ? (
      item.counter ? <i className={item.icon}><em>{item.counter}</em></i> : <i className={item.icon}/>
    ) : null;


    const liClassName = isItemActive(item,location.pathname) ? 'active' : '';

    const link = item.route ?
      <NavLink to={item.route} title={item.title} activeClassName="active" onClick={onLinkClick}>
        {icon} {title} {badge}
      </NavLink> :
      <a href={item.href || '#'}  title={item.title}>
        {icon} {title} {badge}
      </a>;

// className={liClassName}
    return <li data-permission={item.role} className={`${liClassName}`}>{link}{childItems}</li>
  }
export default SmartMenuItem; 


function isItemActive(item, route){
  if(item.route){
    return item.route === route;
  } else if (item.items) {  
    return item.items.some(_ => isItemActive(_, route))
  } else {
    return false
  }
}