import { smallBox } from "..";

export const reloadDatatable = () => $('#data-table_wrapper .dt-buttons #reload-button').trigger('click');

export const addAlertMessage = (_title: string, _content: string, _color: "success" | "error" | "info") => {
    let color = 
    (_color === "success") ? "#659265" 
    : (_color === "error") ? "#c26565"
    : "rgb(83, 132, 175)";
    smallBox({
        title: _title,
        content: _content,
        color: color,
        timeout: 5000,
        icon: "fa fa-bell swing animated"
    });
}

export const addConfirmMessageQ = (_titleQ: string) => {
    smallBox({
        title: _titleQ,
        content:
          "<p class='text-align-right'><button id='yesConfirmDel' class='btn btn-primary btn-sm'>Yes</button> <button class='btn btn-danger btn-sm'>No</button></p>",
        color: "rgb(83, 132, 175)",
        icon: "fa fa-bell swing animated"
    });
}

export const closeModalPopup = (_ButtonCloseSelector: string) => {
    $(_ButtonCloseSelector).attr('data-dismiss', "modal");
    $(_ButtonCloseSelector).trigger("click");
    $(_ButtonCloseSelector).removeAttr('data-dismiss');
}

export const deleteSpaceTrim = (obj: any) => {
    for (const key of Object.keys(obj)) {
        const val = obj[key];
        if(typeof val === 'string' ){
            obj = {...obj, [key]: obj[key].trim()}
        }
    }
    return obj;
}