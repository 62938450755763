import React from "react";
import FullScreen from "./FullScreen";
import ToggleMenu from "./ToggleMenu";
import { Msg } from "../../i18n";
import { LanguageSelector } from "../../i18n";
import { Activities } from "../../activities";
import { Link,useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as NavigationActions from '../../navigation/NavigationActions'
import { SearchMobile } from ".";
import { SmartMessageBox } from "../..";
import { addAlertMessage } from "../../consts/functions";
import SpeechButton from "../../voice-control/components/SpeechButton";
import { LOGOUT } from "../../services/api";

const Header = (props) => {
const history = useHistory();

const logout = async () => {
  const resLogout = await LOGOUT();
  if (resLogout.isSuccessful) {
    history.push('/login');
    addAlertMessage("You are logged out", "<i class='fa fa-clock-o'></i> <i>Have a nice day...</i>", "success");
  } else {
    addAlertMessage(resLogout.displayMessage);
  }
}

  const logutAlertCallback = () => {
    SmartMessageBox(
      {
        title: "Logout",
        content:
          "Are you sure you want to logout ?",
        buttons: "[No][Yes]"
      },
      function (ButtonPressed) {
        if (ButtonPressed === "Yes") {
          logout();        
        }
      }
    );
  }
  return (
    <header id="header">
      <div id="logo-group" style={{ justifyContent: "flex-start" }}>
        <Link to={"/"}>
          <span className="msbit-img-logo">
            <img
              src="assets/img/logo.png" // place your logo here
              alt="SmartAdmin"
            />
          </span>
        </Link>
       <Activities />
        {/* Note: The activity badge color changes when clicked and resets the number to 0
         Suggestion: You may want to set a flag when this happens to tick off all checked messages / notifications */}


      </div>

      {/* <RecentProjects /> */}
      <div className="msbit-project-name"><div> <Msg phrase="project Name" /></div></div>
      <div className="pull-right" /*pulled right: nav area*/>
        <ToggleMenu
          className="btn-header pull-right" /* collapse menu button */
        />

        {/* #MOBILE */}
        {/*  Top menu profile link : this shows only when top menu is active */}
        <ul
          id="mobile-profile-img"
          className="header-dropdown-list hidden-xs padding-5"
        >
          <li className="">
            <a href="#/"
              className="dropdown-toggle no-margin userdropdown"
              data-toggle="dropdown"
            >
              <img
                src="assets/img/avatars/sunny.png"
                alt="John Doe"
                className="online"
              />
            </a>
            <ul className="dropdown-menu pull-right">
              <li>
                <a href="#/" className="padding-10 padding-top-0 padding-bottom-0">
                  <i className="fa fa-cog" /> Setting
                </a>
              </li>
              <li className="divider" />
              <li>
                <a
                  href="#/views/profile"
                  className="padding-10 padding-top-0 padding-bottom-0"
                >
                  <i className="fa fa-user" />
                  <u>P</u>rofile
                </a>
              </li>
              <li className="divider" />
              <li>
                <a href="#/"
                  className="padding-10 padding-top-0 padding-bottom-0"
                  data-action="toggleShortcut"
                >
                  <i className="fa fa-arrow-down" /> <u>S</u>hortcut
                </a>
              </li>
              <li className="divider" />
              <li>
                <a href="#/"
                  className="padding-10 padding-top-0 padding-bottom-0"
                  data-action="launchFullscreen"
                >
                  <i className="fa fa-arrows-alt" /> Full
                  <u>S</u>creen
                </a>
              </li>
              <li className="divider" />
              <li>
                <a
                  href="#/login"
                  className="padding-10 padding-top-5 padding-bottom-5"
                  data-action="userLogout"
                >
                  <i className="fa fa-sign-out fa-lg" />
                  <strong>
                    <u>L</u>ogout
                  </strong>
                </a>
              </li>
            </ul>
          </li>
        </ul>

        {/* logout button */}
        <div id="logout" className="btn-header transparent pull-right">
          <span>
            <button
              // to="/login"
              onClick={logutAlertCallback}
              title="Sign Out"
              id="logoutBtn"
            >
              <i className="fa fa-sign-out" />
            </button>
          </span>
        </div>

        {/* search mobile button (this is hidden till mobile view port) */}
        <SearchMobile className="btn-header transparent pull-right" />

        {/* input: search field */}
        <form
          action="#/misc/search.html"
          className="header-search pull-right"
        >
          <input
            id="search-fld"
            type="text"
            name="param"
            placeholder="Find reports and more"
          />
          <button type="submit">
            <i className="fa fa-search" />
          </button>
          <a href="$" id="cancel-search-js" title="Cancel Search">
            <i className="fa fa-times" />
          </a>
        </form>

        <SpeechButton className="btn-header transparent pull-right hidden-sm hidden-xs" />

        <FullScreen className="btn-header transparent pull-right" />

        {/* multiple lang dropdown : find all flags in the flags page */}
        <LanguageSelector />
      </div>
      {/* end pulled right: nav area */}
    </header>
  );
}

export default connect(
  store => store.navigation,
  dispatch => bindActionCreators(NavigationActions, dispatch)
)(Header);