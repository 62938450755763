import React from "react";

import { connect } from "react-redux";

interface IMsgProps {
  phrase: string;
  phrases: {[key: string]: string};
}

class Msg extends React.Component<IMsgProps> {
  render() {
    const key = this.props.phrase || '';
    const phrase = this.props.phrases[key] || key;
    return <>{phrase}</>;
  }
}

const mapStateToProps = (state: any) => state.i18n;

export default connect(mapStateToProps)(Msg);