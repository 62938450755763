import React from "react";
import { Sparkline } from "../../graphs/inline";

interface IStatsProps {
  statsData: IStatsData[];
}

interface IStatsData {
  title: string;
  total: string;
  values: number[];
  color: "greenDark" | "purple" | "blue";
  icon: string;
}

const Stats: React.FC<IStatsProps> = (props) => {
  return (
    <div className="col-12">
    {props.statsData &&  <ul id="sparks">
        {props.statsData.map((item: IStatsData , i: number) => {
          return (
            <li className={`sparks-info ${props.statsData.length>2 && 'spark-info-more-2'}`} key={i}>
              <h5 className="m-0">
                #{item.title}
                <span className={`txt-color-${item.color}`}>
                  <i className={`${item.icon}`} />
                  {item.total}
                </span>
              </h5>
              <Sparkline
                values={item.values}
                className={`txt-color-${item.color} hidden-mobile msbit-hidden-md`}
              />
            </li>
          )
        })}
      </ul>}
    </div>
  );
}
export default Stats;