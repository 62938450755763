import Loadable from "react-loadable";
import { Loading } from "../../common";

const Dashboard = Loadable({
    loader: () => import('./dashboard/dashboard'),
    loading: Loading
});

const UsersViewAdmin = Loadable({
    loader: () => import('./users/usersViewAdmin'),
    loading: Loading
});

const UsersViewParent = Loadable({
    loader: () => import('./users/usersViewParents'),
    loading: Loading
});

const UsersViewChildren = Loadable({
    loader: () => import('./users/usersViewChildren'),
    loading: Loading
});

const UserActivity = Loadable({
    loader: () => import('./users/userActivity'),
    loading: Loading
})

const UsersMessages = Loadable({
    loader: () => import('./users/usersMessages'),
    loading: Loading
})

const ViewPrizes = Loadable({
    loader: () => import('./users/viewPrizes'),
    loading: Loading
})

const ContentPages = Loadable({
    loader: () => import('./users/contentPages'),
    loading: Loading
})

const ContentGallery = Loadable({
    loader: () => import('./users/contentGallery'),
    loading: Loading
})

export const routes = [
    {
        path: "/",
        exact: true,
        component: Dashboard,
        name: 'dashboard'
    },
    {
        path: "/users/admin",
        exact: true,
        component: UsersViewAdmin,
        name: 'adminUsersView'
    },
    {
        path: "/users/parent",
        exact: true,
        component: UsersViewParent,
        name: 'parentUsersView'
    },
    {
        path: "/users/children",
        exact: true,
        component: UsersViewChildren,
        name: 'childUsersView'
    },
    {
        path: '/users/activity',
        exact: true,
        component: UserActivity,
        name: 'userActivity'
    },
    {
        path: '/users/messages',
        exact: true,
        component: UsersMessages,
        name: 'usersMessages'
    },
    {
        path: '/users/prizes',
        exact: true,
        component: ViewPrizes,
        name: 'usersPrizes'
    },
    {
        path: '/content/pages',
        exact: true,
        component: ContentPages,
        name: 'contentPages'
    },
    {
        path: '/content/gallery',
        exact: true,
        component: ContentGallery,
        name: 'contentGallery'
    }
]