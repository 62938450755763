import React, { useEffect } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Collapse } from "react-bootstrap";
import { Link,useLocation} from "react-router-dom";
import profile from '../../../assets/icon-images/profile.png';
import * as NavigationActions from '../NavigationActions'
import { Msg } from "../../i18n";

const Shortcut = props => {

  let location = useLocation()

  useEffect(() => {
    if (props.shortcutOpen) {
      $("#main").on('click', function () { props.closeShortcut();});
    }
     return () => $("#main").off('click');
  }, [props.shortcutOpen]);

  const onLinkClick = (e)=>{
    props.closeShortcut()
  }

  const onBackClick = (e) => {
    e.preventDefault()
    props.closeShortcut()
  }

  return (
    <Collapse in={props.shortcutOpen} mountOnEnter={true} unmountOnExit={true}>
      <div id="shortcut" style={{ display: 'block' }}>
        <ul onClick={onBackClick}>
          <li>
            <Link onClick={onLinkClick}
              to="/profile"
              className={`jarvismetro-tile big-cubes bg-color-pinkDark 
              ${(location.pathname === '/profile') && "selected"}`
              }
            >
              <span className="iconbox">
                <img src={profile} alt="profile" className="msbit-shortcut-nav-img" /> <span><Msg phrase='profile'/></span>
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </Collapse>
  );
}


export default connect(
  store => store.navigation,
  dispatch => bindActionCreators(NavigationActions, dispatch)
)(Shortcut);


