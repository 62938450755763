import './assets/css/msbit.smartadmin.scss';
import React, { Component } from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/configureStore";
import { authRoutes } from "./routes";
import { Layout } from "./common/layout";
import './App.scss'

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <HashRouter>
          <Switch>
            {authRoutes.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  render={props => <route.component {...props} />}
                />
              ) : null;
            })}

            <Route path="/" component={Layout} />
            {/* <Redirect path='*' exact={true} to="/" /> */}
            {/* test comment sdlfjsaldkj */}
          </Switch>
        </HashRouter>
      </Provider>
    );
  }
}

export default App;
